@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

$px-font: 'Space Grotesk', sans-serif !default;

// Theme Variable

$px-theme: #B8860B;
$px-theme-rgb: 7, 136, 255;
$px-white: #fff;
$px-black: #ffffff;

$px-text: #707476;
$px-heading: #fff;

// Breakpoints
$px-media-xs: 393px;
$px-media-xsm: 480px; // Add a value for $px-media-xsm
$px-media-sm: 576px;
$px-media-md: 768px;
$px-media-lg: 992px;
$px-media-xlg: 1200px;
